import Vue from 'vue'
import Router from 'vue-router'
import { isUserLoggedIn } from '@/helpers/utils'
import routes from './routes.js'
import env from '../env.js'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes
});

(function () {
  const appLoading = document.getElementById('applicationBusyPage')
  if (appLoading) {
      appLoading.style.display = "none";
  }
})();

router.afterEach((to, from, next) => {
  document.title = to.meta.pageTitle+' | '+env.appName
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  if(to.meta.authRequired) {
    if (!isLoggedIn) return next({path: '/auth', query:{ token: to.query.token, intended_url: to.path } });
  }
  if(to.meta.guestRequired) {
    if (isLoggedIn) return next({path: '/' });
  }
  return next();
});

export default router

