export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES - Main Routes
    // =============================================================================
        path: '',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                    pageTitle: "Dashboard",
                    authRequired: true,
                }
            },
            {
                path: '/account',
                name: 'account',
                component: () => import('@views/account/UserEdit.vue'),
                meta: {
                    breadcrumb: [
                        { title: 'Home', url: '/dashboard' },
                        { title: 'Account', active: true },
                    ],
                    pageTitle: 'Profile',
                    authRequired: true,
                },
            },
            {
                path: '/orders',
                name: 'orders',
                component: () => import('@views/orders/OrderList.vue'),
                meta: {
                    breadcrumb: [
                        { title: 'Home', url: '/dashboard' },
                        { title: 'Orders', active: true },
                    ],
                    pageTitle: 'Orders',
                    authRequired: true,
                },
            },
            {
                path: '/orders/:orderId(\\d+)',
                name: 'order-view',
                component: () => import('@views/orders/OrderShow.vue'),
                meta: {
                    breadcrumb: [
                        { title: 'Home', url: '/dashboard' },
                        { title: 'Pending', url: '/orders?status=pending' },
                        { title: 'Delivered', url: '/orders?status=delivered' },
                        { title: 'Completed', url: '/orders?status=completed' },
                        { title: 'Order View', active: true },
                    ],
                    pageTitle: 'Orders',
                    authRequired: true,
                },
            },
            {
                path: '/chat',
                name: 'chat',
                component: () => import('@views/chat/ChatRedirect.vue'),
                meta: {
                    authRequired: true,

                }
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: () => import('@views/notification/Notifications.vue'),
                meta: {
                    breadcrumb: [
                        { title: 'Home', url: '/dashboard' },
                        { title: 'Notification List', active: true },
                    ],
                    pageTitle: 'Notifications',
                    authRequired: true,
                }
            },
        ]
    },
   // =============================================================================
   // FULL PAGE LAYOUTS
   // =============================================================================
   {
       path: '/',
       component: () => import('@/layouts/FullPage.vue'),
       children: [
           {
               path: '/error-404',
               name: 'error-404',
               component: () => import('@views/pages/Error404.vue'),
               meta:{
                    pageTitle: '404 Error',
               }
           },
           {
               path: '/error-500',
               name: 'error-500',
               component: () => import('@views/pages/Error500.vue'),
               meta:{
                    pageTitle: '500 Error',
               }
           },
           {
             path: '/auth',
             name: 'auth',
             component: () => import('@/views/pages/Authenticate.vue'),
             meta:{
                pageTitle: 'Auto Login',
             }
         },
       ]
   },
   // Redirect to 404 page, if no match found
   {
       path: '*',
       redirect: '/error-404'
   },

]