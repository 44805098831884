
import axios from "@/plugins/axios.js"

const actions = {
    alertSuccess({}, message) {
      this._vm.$vs.notify({
        title:'Success',
        text:message,
        color:'success',
        iconPack: 'feather',
        icon:'icon-check',
        position:'top-right'})
    },
    alertError({}, message) {
      this._vm.$vs.notify({
        title:'Error',
        time:8000,
        text: message,
        color:'warning',
        iconPack: 'feather',
        icon:'icon-alert-circle',
        position:'top-right'})
    },
    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////
    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },
    universalFetch({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios.get(payload.action)
        .then((response) => {
            resolve(response)
        }).catch((error) => { reject(error) })
      })
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////
    fetchActiveUser({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios.get('/account/show')
        .then((response) => {
            if(response.data.success){
              commit('UPDATE_USER_INFO', response.data.data)
            }
            resolve(response)
        }).catch((error) => { reject(error) })
      })
    },

    logoutUser({ commit, rootState }, action) {
        commit("LOGOUT_USER");
        return new Promise((resolve, reject) => {
          axios.post(`${rootState.domain}/api/logout`)
          .then((response) => {
              resolve(response)
          }).catch((error) => { reject(error) })
        })
    },


     // /////////////////////////////////////////////
    // Chats
    // /////////////////////////////////////////////
    updateUserChatInfo({ commit, rootState }, payload) {
        let formData = new FormData()
        Object.keys(payload).forEach((index) => {
            formData.append(index, payload[index])
        })
        return new Promise((resolve, reject) => {
            axios.post(`${rootState.domain}/api/app/chats/update-info`, formData)
            .then((response) => {
                resolve(response)
            }).catch((error) => { reject(error) })
        })
    },
    recallChatCount({ commit, rootState }){
        return new Promise((resolve, reject) => {
            axios.get(`${rootState.domain}/api/app/chats/count-unread`)
            .then((response) => {
            if(response.data.success){
                commit('UPDATE_USER_UNREAD_CHAT', response.data.data.unread_chats)
            }else{
                console.log(response.data.error)
            }
            resolve(response)
            }).catch((error) => { reject(error) })
        })
    },

    // /////////////////////////////////////////////
    // Countries
    // /////////////////////////////////////////////
    fetchCountries({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            axios.get(`${rootState.domain}/api/app/countries`)
            .then((response) => {
            if(response.data.success){
              commit('SET_COUNTRIES', response.data.data.countries)
            }
            resolve(response)
            }).catch((error) => { reject(error) })
        })
    },

    // /////////////////////////////////////////////
    // Languages
    // /////////////////////////////////////////////
    fetchLanguages({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            axios.get(`${rootState.domain}/api/app/languages`)
            .then((response) => {
            if(response.data.success){
              commit('SET_LANGUAGES', response.data.data.languages)
            }
            resolve(response)
            }).catch((error) => { reject(error) })
        })
    },

}

export default actions
