
import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"


import moduleOrder from './modules/order.js'
import moduleNotification from "./modules/notification.js"


Vue.use(Vuex)

export default new Vuex.Store({
    state,
    actions,
    getters,
    mutations,
    modules: {
        notification: moduleNotification,
        orderList: moduleOrder
    },
    strict: process.env.NODE_ENV !== 'production'
})
